.container {
  height: 100%;
  position: relative;
}

.add-contact-fab {
  position: fixed;
  bottom: 5rem;
  right: 1rem;
}
