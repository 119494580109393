.networks-list-container {
  padding: 1rem 0.5rem;
}

.content {
  position: relative;
}

.add-network-fab-button {
  position: absolute;
  bottom: 5rem;
  right: 1rem;
}
